import { UserRoles } from 'interfaces';
import React from 'react';
import { useAppSelector } from 'store';

type ProtectedComponentProps = {
  children: React.ReactElement<any, any>;
};

const ProtectedComponent = ({ children }: ProtectedComponentProps) => {
  const { user } = useAppSelector(({ auth }) => auth);

  const isAuthorized = user?.role === UserRoles.ADMIN;

  if (!isAuthorized) {
    return null;
  }

  return children;
};

export default ProtectedComponent;
