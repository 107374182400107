import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { ILocale, GPSLocale } from 'interfaces';
import { api, mapsApi } from 'api';
import debounce from 'lodash.debounce';
import { DebouncedFunc } from 'lodash';

import { ReactComponent as Minimize } from 'assets/minimize.svg';
import { ReactComponent as Maximize } from 'assets/maximize.svg';
import PinSelected from 'assets/pin-selected.svg';
import Pin from 'assets/pin.svg';

type MapContentProps = {
  localeInfo: ILocale | null;
  mapsExpanded: boolean;
  changeLocale: (id: string) => void;
  setMapsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

type MapComponentProps = {
  isLoaded: boolean;
  mapsExpanded: boolean;
  onBoundsChanged: () => void;
  onLoad: (map: google.maps.Map) => void;
  onUnmount: () => void;
  markers: GPSLocale[];
  setSelectedMaker: React.Dispatch<React.SetStateAction<GPSLocale | undefined>>;
  selectedMaker: GPSLocale | undefined;
};

const MapComponent = memo(
  function MapComponent({
    isLoaded,
    mapsExpanded,
    onBoundsChanged,
    onLoad,
    onUnmount,
    markers,
    setSelectedMaker,
    selectedMaker,
  }: MapComponentProps) {
    return (
      <>
        {isLoaded ? (
          <GoogleMap
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              zoomControl: false,
            }}
            mapContainerStyle={{
              width: '100%',
              aspectRatio: 1,
              borderRadius: '4px',
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onBoundsChanged={onBoundsChanged}
          >
            {mapsExpanded ? (
              markers.map((marker) => (
                <MarkerF
                  onClick={() => setSelectedMaker(marker)}
                  key={marker.id}
                  icon={marker.id === selectedMaker?.id ? PinSelected : Pin}
                  position={{
                    lat: marker!.latitude!,
                    lng: marker!.longitude!,
                  }}
                />
              ))
            ) : (
              <MarkerF
                icon={PinSelected}
                position={{
                  lat: selectedMaker?.latitude || 0,
                  lng: selectedMaker?.longitude || 0,
                }}
              />
            )}
          </GoogleMap>
        ) : (
          <></>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.isLoaded === nextProps.isLoaded &&
    prevProps.mapsExpanded === nextProps.mapsExpanded &&
    prevProps.selectedMaker === nextProps.selectedMaker &&
    prevProps.markers.length === nextProps.markers.length
);

const MapContent = ({
  localeInfo,
  mapsExpanded,
  setMapsExpanded,
  changeLocale,
}: MapContentProps) => {
  const fetchCoords = useRef<DebouncedFunc<() => Promise<void>>>(
    debounce(() => {}, 300)
  );
  const [selectedMaker, setSelectedMaker] = useState<GPSLocale | undefined>(
    localeInfo
      ? {
          id: localeInfo.id,
          name: localeInfo.name,
          latitude: localeInfo.latitude,
          longitude: localeInfo.longitude,
        }
      : undefined
  );
  const [markers, setMarkers] = useState<ILocale[]>([]);
  const [markerAddress, setMarkerAddress] = useState<string>('');
  const [mapsRef, setMapsRef] = useState<google.maps.Map | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      const bounds = new window.google.maps.LatLngBounds({
        lat: (localeInfo?.latitude as number) || -23.533773,
        lng: (localeInfo?.longitude as number) || -46.62529,
      });
      map.setZoom(20);
      map.fitBounds(bounds, 10);

      setMapsRef(map);
    },
    [localeInfo]
  );

  const onUnmount = useCallback(() => {
    setMapsRef(null);
  }, []);

  useEffect(() => {
    if (mapsExpanded) {
      mapsRef?.setZoom(15);
      return;
    } else {
      setMarkers([]);
      setSelectedMaker(
        localeInfo
          ? {
              id: localeInfo.id,
              name: localeInfo.name,
              latitude: localeInfo.latitude,
              longitude: localeInfo.longitude,
            }
          : undefined
      );
      mapsRef?.setZoom(20);
    }

    mapsRef?.setCenter({
      lat: localeInfo?.latitude as number,
      lng: localeInfo?.longitude as number,
    });
  }, [mapsExpanded, mapsRef, localeInfo]);

  useEffect(() => {
    if (mapsRef) {
      fetchCoords.current = debounce(async () => {
        const bounds = mapsRef?.getBounds();
        if (!bounds) {
          return;
        }
        const { data } = await api.post('/locales/coordinates', {
          southwest: bounds.getSouthWest().toJSON(),
          northeast: bounds.getNorthEast().toJSON(),
        });
        setMarkers(data);
      }, 300);
    }
  }, [mapsRef]);

  useEffect(() => {
    if (selectedMaker?.id === localeInfo?.id || !mapsExpanded) {
      return;
    }
    const latlng = `${selectedMaker?.latitude?.toFixed(
      5
    )},${selectedMaker?.longitude?.toFixed(5)}`;
    mapsApi
      .get(
        `/json?latlng=${latlng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
      .then(({ data }) => {
        if (data) {
          const results = data.results;

          if (results.length > 0) {
            const bestAddress = results.reduce(
              (acc: any, cur: any) => {
                if (cur.address_components.length > acc.components) {
                  return {
                    address: cur.formatted_address,
                    components: cur.address_components.length,
                  };
                }
                return acc;
              },
              { address: '', components: 0 }
            );

            setMarkerAddress(bestAddress.address);
          }
        }
      });
  }, [localeInfo?.id, selectedMaker, mapsExpanded]);

  return (
    <>
      <Stack direction="row" alignItems="center" mb={2}>
        <IconButton
          color="primary"
          size="small"
          onClick={() => setMapsExpanded((p) => !p)}
        >
          {mapsExpanded ? <Minimize /> : <Maximize />}
        </IconButton>
        <Typography variant="subtitle1">Mapa</Typography>
      </Stack>
      {localeInfo && localeInfo?.latitude ? (
        <>
          <MapComponent
            mapsExpanded={mapsExpanded}
            isLoaded={isLoaded}
            markers={markers}
            onLoad={onLoad}
            onBoundsChanged={fetchCoords.current}
            onUnmount={onUnmount}
            setSelectedMaker={setSelectedMaker}
            selectedMaker={selectedMaker}
          />
          {mapsExpanded &&
            selectedMaker &&
            selectedMaker.id !== localeInfo?.id && (
              <Stack
                direction="row"
                mt={2}
                bgcolor="white"
                p={2}
                borderRadius={1}
                justifyContent="space-between"
              >
                <Stack direction="column">
                  <Typography variant="caption" color="text.secondary">
                    Pasta
                  </Typography>
                  <Typography variant="body1">{selectedMaker?.name}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Endereço
                  </Typography>
                  <Typography variant="body1">{markerAddress}</Typography>
                </Stack>
                <Button
                  onClick={() => changeLocale(selectedMaker.id)}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: 30 }}
                >
                  Abrir
                </Button>
              </Stack>
            )}
        </>
      ) : (
        <Typography variant="body1" alignSelf="center">
          Não possui coordenadas cadastradas
        </Typography>
      )}
    </>
  );
};

export default MapContent;
