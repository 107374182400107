import axios from 'axios';
import PQueue from 'p-queue';

export const downloadFileWithProgress = async (
  url: string,
  downloadName: string,
  callback: (downloadedSize: number, totalSize: number) => void
) => {
  try {
    const partSize = 1024 * 1024 * 10; // 10MB
    const { headers } = await axios.head(url);
    const fileSize = parseInt(headers['content-length'], 10);
    const totalParts = Math.ceil(fileSize / partSize);

    const parts: Uint8Array[] = [];
    const queue = new PQueue({ concurrency: 20 });

    for (let part = 0; part < totalParts; part++) {
      const start = part * partSize;
      const end = Math.min(start + partSize - 1, fileSize - 1);

      queue.add(async () => {
        const response = await axios.get(url, {
          headers: {
            Range: `bytes=${start}-${end}`,
          },
          responseType: 'arraybuffer',
        });

        parts[part] = response.data;
        callback(end - start, fileSize);
      });
    }

    await queue.onIdle();

    const blob = new Blob(parts);

    const href = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', downloadName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    throw new Error('Error downloading file');
  }
};
