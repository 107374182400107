import { Typography, Stack, CircularProgress, Divider } from '@mui/material';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import dayjs from 'dayjs';
import { IImageMetadata } from 'interfaces';
import { useCallback } from 'react';

const InfoContent = ({
  imageMetadata,
  isLoading,
}: {
  imageMetadata: IImageMetadata | null;
  isLoading: boolean;
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      const bounds = new window.google.maps.LatLngBounds({
        lat: (imageMetadata?.latitude as number) || -23.533773,
        lng: (imageMetadata?.longitude as number) || -46.62529,
      });
      map.setZoom(20);
      map.fitBounds(bounds, 10);
    },
    [imageMetadata]
  );

  return (
    <>
      <Typography variant="subtitle1" mb={2}>
        Informações
      </Typography>
      {isLoading ? (
        <Stack p={2} width="100%" direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : imageMetadata ? (
        <>
          <Stack
            direction="column"
            bgcolor="primary.light"
            px={2}
            py={1}
            mb={2}
            borderRadius={1}
          >
            <Typography
              variant="overline"
              color="primary.contrastText"
              fontSize={14}
              lineHeight="26px"
            >
              {imageMetadata.Model || 'n/a'}
            </Typography>
            <Typography
              variant="overline"
              color="primary.contrastText"
              fontSize={14}
              lineHeight="26px"
            >
              {imageMetadata.LensModel || 'n/a'}
            </Typography>
            <Typography
              variant="overline"
              color="primary.contrastText"
              fontSize={14}
              lineHeight="26px"
              textTransform="none"
            >
              {imageMetadata.ExifImageWidth || 'n/a'} x{' '}
              {imageMetadata.ExifImageHeight || 'n/a'}{' '}
              {imageMetadata.Size
                ? `${(imageMetadata.Size as number)
                    .toFixed(2)
                    .replace('.', ',')} MB`
                : 'n/a'}{' '}
              JPG
            </Typography>
            <Divider sx={{ bgcolor: 'white', mb: 1, mt: 1 }} />
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                Distância Focal
              </Typography>
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                {imageMetadata.FocalLength || 'n/a'} mm
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                Vel. obturador
              </Typography>
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                {imageMetadata.ExposureTime
                  ? `1/${1 / (imageMetadata.ExposureTime as number)} seg`
                  : 'n/a'}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                Abertura
              </Typography>
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                {imageMetadata.ApertureValue
                  ? `f/${(imageMetadata.ApertureValue as number).toFixed(2)}`
                  : 'n/a'}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                ISO
              </Typography>
              <Typography
                variant="caption"
                color="primary.contrastText"
                fontSize={14}
              >
                {imageMetadata.ISO || 'n/a'}
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={2}>
            <Typography variant="caption" color="primary.light">
              Nome do arquivo
            </Typography>
            <Typography variant="body1" color="primary.light">
              {imageMetadata.name}
            </Typography>
            <Typography variant="caption" color="primary.light" mt={1}>
              Captura
            </Typography>
            <Typography variant="body1" color="primary.light">
              {imageMetadata?.DateTimeOriginal ||
              imageMetadata?.CreateDate ||
              imageMetadata?.DateTime
                ? dayjs(
                    (imageMetadata?.DateTimeOriginal ||
                      imageMetadata?.CreateDate ||
                      imageMetadata?.DateTime) as string
                  ).format('DD/MM/YYYY HH:mm:ss')
                : 'n/a'}
            </Typography>
            <Typography variant="caption" color="primary.light" mt={1}>
              GPS
            </Typography>
            <Typography variant="body1" color="primary.light">
              {imageMetadata.GPSLatitude
                ? (imageMetadata.GPSLatitude as number[])[0] +
                  'º' +
                  (imageMetadata.GPSLatitude as number[])[1].toFixed(0) +
                  "\\'" +
                  ((imageMetadata.GPSLatitude as number[])[1] % 1)
                    .toFixed(2)
                    .split('.')[1] +
                  '"' +
                  imageMetadata.GPSLatitudeRef +
                  ' ' +
                  (imageMetadata.GPSLongitude as number[])[0] +
                  'º' +
                  (imageMetadata.GPSLongitude as number[])[1].toFixed(0) +
                  "\\'" +
                  ((imageMetadata.GPSLongitude as number[])[1] % 1)
                    .toFixed(2)
                    .split('.')[1] +
                  '"' +
                  imageMetadata.GPSLongitudeRef
                : 'n/a'}
            </Typography>
          </Stack>
          {imageMetadata.latitude && (
            <>
              {isLoaded ? (
                <GoogleMap
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoom: 20,
                  }}
                  mapContainerStyle={{
                    width: '100%',
                    aspectRatio: 1,
                    borderRadius: '4px',
                  }}
                  center={{
                    lat: imageMetadata.latitude as number,
                    lng: imageMetadata.longitude as number,
                  }}
                  onLoad={onLoad}
                >
                  <MarkerF
                    position={{
                      lat: imageMetadata.latitude as number,
                      lng: imageMetadata.longitude as number,
                    }}
                  />
                </GoogleMap>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <Typography variant="body1" alignSelf="center">
          Metadados não disponíveis
        </Typography>
      )}
    </>
  );
};

export default InfoContent;
