import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AllLocales } from 'interfaces';

interface LibraryState {
  selectedFolders: string[];
}

const initialState: LibraryState = {
  selectedFolders: [],
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    SET_SELECTED_FOLDERS: (state, action: PayloadAction<string[]>) => {
      state.selectedFolders = action.payload;
    },
    ADD_FOLDERS: (state, action: PayloadAction<string[]>) => {
      state.selectedFolders = state.selectedFolders.concat(action.payload);
    },
    REMOVE_FOLDERS: (state, action: PayloadAction<string[]>) => {
      state.selectedFolders = state.selectedFolders.filter(
        (f) => !action.payload.includes(f)
      );
    },
  },
});

export const { ADD_FOLDERS, REMOVE_FOLDERS, SET_SELECTED_FOLDERS } =
  librarySlice.actions;

export default librarySlice.reducer;
