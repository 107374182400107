import { useEffect, useState } from 'react';

const useKeyListener = (key: string) => {
  const [isPressed, setPressed] = useState(false);

  useEffect(() => {
    const onDown = (event: KeyboardEvent) => {
      if (event.key === key) {
        setPressed(true);
      }
    };

    const onUp = (event: KeyboardEvent) => {
      if (event.key === key) {
        setPressed(false);
      }
    };

    window.addEventListener('keydown', onDown);
    window.addEventListener('keyup', onUp);

    return () => {
      window.removeEventListener('keydown', onDown);
      window.removeEventListener('keyup', onUp);
    };
  }, [key]);

  return isPressed;
};

export default useKeyListener;
