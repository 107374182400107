import axios from 'axios';
import { config } from 'bluebird';

export const mapsApi = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api/geocode',
});

mapsApi.interceptors.request.use((config) => {
  config.params = {
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  };

  return config;
});
