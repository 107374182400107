import {
  Box,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Avatar,
  ListItem,
  ListItemAvatar,
  IconButton,
  CSSObject,
  Theme,
  styled,
  BoxProps,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { ReactComponent as Dashboard } from 'assets/dashboard.svg';
import { ReactComponent as Tag } from 'assets/tag.svg';
import { ReactComponent as Users } from 'assets/users.svg';
import { ReactComponent as Bell } from 'assets/bell.svg';
import { ReactComponent as Settings } from 'assets/setting.svg';
import { ReactComponent as LogOut } from 'assets/log-out.svg';
import { grey } from '@mui/material/colors';

const drawerWidth = 336;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
});

interface StyledBoxProps extends BoxProps {
  open?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, open }) => ({
  ...(open && {
    ...openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));

const DrawerMenu = ({
  open,
  selected,
  setSelected,
}: {
  open: boolean;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  return (
    <StyledBox open={open} flexDirection="column" bgcolor="grey.100">
      <MenuList
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          '& li': {
            paddingY: 1.3,
          },
        }}
      >
        <ListItem
          sx={{ marginTop: 1.5, marginBottom: 3 }}
          secondaryAction={
            <IconButton edge="end" aria-label="logout" onClick={logout}>
              <LogOut />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>{user?.name[0]}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={user?.name}
            sx={{ color: grey[800], fontWeight: 500 }}
          />
        </ListItem>
        <MenuItem sx={{ paddingLeft: 4 }} selected={selected === 'dashboard'}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText sx={{ color: 'grey.800' }}>Dashboard</ListItemText>
          <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
        </MenuItem>
        <MenuItem
          sx={{ paddingLeft: 4 }}
          onClick={() => {
            setSelected('catgegory');
            navigate('/categorias');
          }}
          selected={selected === 'category'}
        >
          <ListItemIcon>
            <Tag />
          </ListItemIcon>
          <ListItemText sx={{ color: 'grey.800' }}>Categorias</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{ paddingLeft: 4 }}
          onClick={() => {
            setSelected('access-control');
            navigate('/controle-acesso');
          }}
          selected={selected === 'access-control'}
        >
          <ListItemIcon>
            <Users />
          </ListItemIcon>
          <ListItemText sx={{ color: 'grey.800' }}>
            Controle de acesso
          </ListItemText>
        </MenuItem>
        <MenuItem sx={{ paddingLeft: 4 }}>
          <ListItemIcon>
            <Bell />
          </ListItemIcon>
          <ListItemText sx={{ color: 'grey.800' }}>Notificações</ListItemText>
          <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
        </MenuItem>
        <MenuItem sx={{ paddingLeft: 4 }}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText sx={{ color: 'grey.800' }}>Configurações</ListItemText>
          <Chip label="Em breve" sx={{ color: 'grey.800' }} size="small" />
        </MenuItem>
      </MenuList>
    </StyledBox>
  );
};

export default DrawerMenu;
