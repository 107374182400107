import { createSlice } from '@reduxjs/toolkit';

interface DownloadState {
  open: boolean;
  done: boolean;
  folder: string;
  progress: number;
  downloadedSize: number;
}

const initialState: DownloadState = {
  folder: '',
  progress: 0,
  open: false,
  done: false,
  downloadedSize: 0,
};

export const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    INIT_DOWNLOAD: (state, { payload }: { payload: string }) => {
      state.folder = payload;
      state.progress = 0;
      state.done = false;
      state.open = false;
      state.downloadedSize = 0;
    },
    UPDATE_PERCENTAGE: (
      state,
      {
        payload,
      }: {
        payload: { downloaded: number; totalSize: number };
      }
    ) => {
      const aux = state.downloadedSize + payload.downloaded;
      const percentage = Math.round((aux / payload.totalSize) * 100);
      state.downloadedSize = aux;
      state.progress = percentage > 100 ? 100 : percentage;
      state.done = percentage === 100;
    },
    FINISH_DOWNLOAD: (state) => {
      state.done = true;
      state.progress = 100;
      state.folder = '';
    },
    CLOSE_DOWNLOAD: (state) => {
      state.open = false;
    },
    TOGGLE_DOWNLOAD: (state) => {
      state.open = !state.open;
    },
    ERROR_DOWNLOAD: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
});

export const {
  INIT_DOWNLOAD,
  UPDATE_PERCENTAGE,
  FINISH_DOWNLOAD,
  CLOSE_DOWNLOAD,
  ERROR_DOWNLOAD,
  TOGGLE_DOWNLOAD,
} = downloadSlice.actions;

export default downloadSlice.reducer;
