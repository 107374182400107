import { Box, IconButton } from '@mui/material';
import { ReactComponent as Home } from 'assets/home.svg';
import { ReactComponent as Books } from 'assets/books.svg';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const Header = ({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const navigate = useNavigate();
  return (
    <Box
      height="48px"
      width="100%"
      bgcolor="primary.main"
      display="flex"
      flexDirection="row"
      sx={{
        button: {
          '&:hover': {
            backgroundColor: 'grey.400',
            svg: { fill: '#2B2B2B' },
          },
        },
      }}
    >
      <IconButton
        onClick={() => {
          setSelected('category');
          navigate('/categorias');
        }}
        sx={{
          aspectRatio: 1,
          bgcolor: selected !== 'library' ? grey[100] : 'primary.dark',
          borderRadius: 0,
        }}
      >
        <Home fill={selected !== 'library' ? 'primary.main' : 'white'} />
      </IconButton>
      <IconButton
        onClick={() => {
          setSelected('library');
          navigate('/biblioteca');
        }}
        sx={{
          aspectRatio: 1,
          bgcolor: selected === 'library' ? grey[100] : 'primary.dark',
          borderRadius: 0,
        }}
      >
        <Books fill={selected === 'library' ? 'primary.main' : 'white'} />
      </IconButton>
    </Box>
  );
};

export default Header;
