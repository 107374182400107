import { useCallback, useEffect, useState, useRef } from 'react';
import exifr from 'exifr';

import { useAppSelector, useAppDispatch } from 'store';

import { useKeyListener, useLocales } from 'hooks';
import {
  SET_SELECTED_FOLDERS,
  REMOVE_FOLDERS,
  ADD_FOLDERS,
} from 'store/library.slice';
import { SET_LOCALE_BROWSER } from 'store/locales.slice';
import { TOGGLE_DOWNLOAD } from 'store/download.slice';
import { AllLocales } from 'interfaces';

const useLibrary = () => {
  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isSelectMode, setSelectMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { selectedFolders } = useAppSelector(({ library }) => library);

  const { getLocaleById, allLocales, localeBrowser } = useLocales();
  const isCtrlPressed = useKeyListener('Control');
  const isShiftPressed = useKeyListener('Shift');

  useEffect(() => {
    if (!isSelectMode) {
      dispatch(SET_SELECTED_FOLDERS([]));
    }
  }, [dispatch, isSelectMode]);

  const handleSelectFolder = (selectLocale: AllLocales) => {
    const { id } = selectLocale;
    if (isCtrlPressed) {
      if (selectedFolders.includes(id)) {
        dispatch(REMOVE_FOLDERS([id]));
      } else {
        dispatch(ADD_FOLDERS([id]));
      }
      return;
    }
    if (isSelectMode) {
      if (isShiftPressed) {
        if (selectedFolders.length > 1) {
          dispatch(SET_SELECTED_FOLDERS([id]));
          return;
        }
        const joined = [...selectedFolders, id];

        const firstMatchIndex = allLocales.findIndex((l) =>
          joined.includes(l.id)
        );
        const lastMatchIndex = [...allLocales]
          .reverse()
          .findIndex((l) => joined.includes(l.id));

        const selected = allLocales
          .slice(firstMatchIndex, allLocales.length - lastMatchIndex)
          .map((l) => l.id);

        dispatch(SET_SELECTED_FOLDERS(selected));
        return;
      }
      let aux;

      if (selectedFolders.includes(id)) {
        aux = selectedFolders.filter((item) => item !== id);
      } else {
        aux = [...selectedFolders, id];
      }
      dispatch(SET_SELECTED_FOLDERS(aux));
      return;
    }
    if (localeBrowser?.id !== id) {
      dispatch(SET_LOCALE_BROWSER(selectLocale));

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        getLocaleById(id);
        timeoutRef.current = null;
      }, 1000);
    }
  };

  const fetchImageMetadata = useCallback(
    async (url: string, callback?: (metadata: any) => void) => {
      setLoading(true);
      try {
        const response = await fetch(url);

        if (response.status !== 200) {
          throw new Error('Image not found');
        }

        const arrayBuffer = await response.arrayBuffer();

        const metadata = await exifr.parse(arrayBuffer);
        callback?.({
          ...metadata,
          Size:
            parseInt(response.headers.get('content-length')!, 10) /
            (1024 * 1024),
        });
      } catch (error) {
        console.error('Error fetching image metadata', error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const toggleDownload = useCallback(() => {
    dispatch(TOGGLE_DOWNLOAD());
  }, [dispatch]);

  const clearSelectedFolders = useCallback(() => {
    dispatch(SET_SELECTED_FOLDERS([]));
    setSelectMode(false);
  }, [dispatch]);

  return {
    isSelectMode,
    setSelectMode,
    selectedFolders,
    handleSelectFolder,
    fetchImageMetadata,
    isLoading,
    toggleDownload,
    clearSelectedFolders,
  };
};

export default useLibrary;
