import {
  CloseRounded,
  FolderRounded,
  CheckCircleRounded,
} from '@mui/icons-material';
import {
  Stack,
  Paper,
  Typography,
  LinearProgress,
  Box,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useAppSelector, useAppDispatch } from 'store';
import { CLOSE_DOWNLOAD } from 'store/download.slice';
import { CLOSE_UPLOAD } from 'store/upload.slice';
import { useUpload } from 'hooks';

const DownloadUpload = () => {
  const dispatch = useAppDispatch();
  const {
    progress,
    open: openDownload,
    done,
    folder,
  } = useAppSelector(({ download }) => download);
  const { total, openUpload, folders } = useUpload();

  if (!openDownload && !openUpload) {
    return null;
  }

  return (
    <Paper
      sx={{
        borderRadius: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        display: 'flex',
        boxShadow: 4,
        zIndex: 5,
        width: '400px',
        overflow: 'hidden',
        position: 'fixed',
        bottom: 0,
        right: 64,
      }}
    >
      <Stack direction="column" width="400px">
        {openDownload && (
          <Stack
            direction="row"
            sx={{
              padding: 2,
              flex: 1,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Stack direction="column" width="100%">
              {folder ? (
                <>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">
                      {done
                        ? 'Download concluído'
                        : 'Download sendo processado'}
                    </Typography>
                    <IconButton
                      onClick={() => dispatch(CLOSE_DOWNLOAD())}
                      size="small"
                      color="primary"
                    >
                      <CloseRounded />
                    </IconButton>
                  </Stack>
                  <Typography variant="body2">
                    {done
                      ? 'Confira sua pasta de download'
                      : 'Seu download está sendo processado. Não feche o navegador até concluir'}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ width: '100%', mt: 1 }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress
                        value={progress}
                        variant={progress ? 'determinate' : 'indeterminate'}
                      />
                    </Box>
                    {!!progress && (
                      <Typography
                        ml={1}
                        variant="body2"
                        color="text.secondary"
                      >{`${progress}%`}</Typography>
                    )}
                  </Stack>
                </>
              ) : (
                <>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">Sem downloads</Typography>
                    <IconButton
                      onClick={() => dispatch(CLOSE_DOWNLOAD())}
                      size="small"
                      color="primary"
                    >
                      <CloseRounded />
                    </IconButton>
                  </Stack>
                  <Typography variant="body2">
                    Não há downloads em andamento
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        )}
        {openUpload && (
          <Stack
            direction="row"
            sx={{
              padding: 2,
              flex: 1,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Stack direction="column" width="100%">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">
                  {`Fazendo upload de ${total} imagens`}
                </Typography>
                <IconButton
                  onClick={() => dispatch(CLOSE_UPLOAD())}
                  size="small"
                  color="primary"
                >
                  <CloseRounded />
                </IconButton>
              </Stack>
              {folders.map((folder) => (
                <Stack
                  direction="row"
                  key={folder.folderCode}
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={1.5}
                >
                  <Stack direction="row" alignItems="center">
                    <FolderRounded color="action" />
                    <Typography
                      variant="body2"
                      paddingLeft={1}
                      mr={1}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      maxWidth={240}
                      textOverflow="ellipsis"
                    >
                      {`Pasta ${folder.folderName}`}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="body2"
                      mr={1}
                    >{`${folder.uploaded}/${folder.total}`}</Typography>
                    {folder.uploaded === folder.total ? (
                      <CheckCircleRounded color="success" />
                    ) : (
                      <CircularProgress size={20} style={{ marginRight: 2 }} />
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default DownloadUpload;
