import { useEffect, useState } from 'react';
import { Box, BoxProps, CSSObject, Theme, styled } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import { DownloadUpload, Drawer, Header } from 'components';
import { useDrawer } from 'hooks';

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('padding-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('padding-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

interface StyledBoxProps extends BoxProps {
  open?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, open }) => ({
  overflow: 'none',
  display: 'flex',
  flex: 1,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));

const Home = () => {
  const navigate = useNavigate();
  const { open, setDrawerOpen } = useDrawer();
  const [selected, setSelected] = useState('library');

  useEffect(() => {
    if (selected === 'library') {
      setDrawerOpen(false);
    } else {
      setDrawerOpen(true);
    }
  }, [selected, setDrawerOpen]);

  useEffect(() => {
    navigate('/biblioteca');
  }, [navigate]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Header selected={selected} setSelected={setSelected} />
      <Box display="flex" flex={1}>
        <Drawer open={open} selected={selected} setSelected={setSelected} />
        <StyledBox open={open} className="test">
          <Outlet />
        </StyledBox>
        <DownloadUpload />
      </Box>
    </Box>
  );
};

export default Home;
